import React, {useEffect, useState} from "react";
import {Container} from "./style";
import {RoundedButton} from "../Quiz/style";

function Home() {
  return (
    <Container>
      <div className="wrapper">
        <p className="start-text">
          How much do you know about periods and menstrual health? Buckle up,
          dear astronaut, and
        </p>
        <RoundedButton
          className="start-btn"
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >
          let's find out
        </RoundedButton>
      </div>
    </Container>
  );
}

export default Home;
