import styled from "styled-components";
import questionsData from "../../data/questions.json";

const Container = styled.div`
  width: 100vw;
  min-height: ${window.innerHeight + "px"};
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .wrapper {
    max-width: 400px;
    min-height: 50%;
    padding: 0 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    margin-top: -20px;
  }

  .placeholder-bg {
    width: 100%;
    height: 10%;
    max-height: 320px;
  }

  .question-container,
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  @media (min-width: 500px) {
    max-width: 300px;
    .wrapper {
      max-width: 400px;
    }
  }

  @media (min-width: 1500px) {
    max-width: 500px;
    .wrapper {
      max-width: 500px;
    }
  }
`;

const CardQuestion = styled.div`
  background-color: #e6007e;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

const CardScore = styled(CardQuestion)`
  background-color: #e6007e;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 30px;
  text-align: center;
`;

const CardOption = styled.p`
  margin: 0;
  border: 1.5px solid #e6007e;
  border-radius: 30px;
  color: #e6007e;
  box-sizing: border-box;
  padding: 10px 20px;
  text-transform: capitalize;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
`;
const CardMessage = styled(CardOption)`
  border-radius: 15px;
  padding: 20px;
  font-size: 16px;
`;

const ResultadoContainer = styled.div`
  /* position: absolute; */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;

  .results-text {
    text-align: center;
    margin: 0;
    font-size: 14px;
  }
`;

const CardHeaderImg = styled.img`
  width: 100%;
  height: auto;
`;

const RoundedButton = styled.div`
  background-color: #e6007e;
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 10px 20px;
  box-sizing: border-box;
  font-family: Kotex-Bold;
  font-weight: bold;
  color: white;
  font-size: 16px;
  width: fit-content;
  align-self: end;
`;

export {
  Container,
  RoundedButton,
  CardQuestion,
  CardOption,
  CardScore,
  ResultadoContainer,
  CardMessage,
  CardHeaderImg,
};
