import {styled} from "styled-components";

const Container = styled.div`
  background-image: url("/images/Cover.png");
  background-size: 100%;
  background-position: 0;
  background-repeat: no-repeat;
  width: ${window.innerWidth + "px"};
  height: ${window.innerHeight + "px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 250px;
    gap: 20px;
  }

  .start-text {
    color: #e6007e;
    text-align: justify;
    font-family: Kotex-Bold;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.28px;
  }

  .start-btn {
    margin-bottom: 45%;
    align-self: center;
    width: 100%;
    text-align: center;
  }

  @media (min-width: 500px) {
    max-width: 400px;
    .wrapper {
      max-width: 300px;
    }

    .start-text {
      font-size: 14px;
    }

    .start-btn {
      margin-bottom: 30%;
    }
  }

  @media (min-width: 1500px) {
    max-width: 600px;
    .wrapper {
      max-width: 400px;
    }
    .start-text {
      font-size: 16px;
    }
  }
`;

export {Container};
