import React, {useEffect, useState} from "react";
import {ResultadoContainer, CardScore, CardMessage} from "../pages/Quiz/style";

const ResultadoQuiz = ({score, mensagensResposta}) => {
  const [titleMessage, setTitleMessage] = useState("");
  const [subtituleMessage, setSubtituleMessage] = useState("");
  const [minPontuacao, setMinPontuacao] = useState(0);
  const [maxPontuacao, setMaxPontuacao] = useState(0);

  const obterMensagemResposta = () => {
    for (const mensagem of mensagensResposta) {
      if (
        score >= mensagem.minPontuacao &&
        score <= mensagem.maxPontuacao
      ) {
        setTitleMessage(mensagem.titulo);
        setSubtituleMessage(mensagem.mensagem);
        setMinPontuacao(mensagem.minPontuacao);
        setMaxPontuacao(mensagem.maxPontuacao);
      }
    }
  };

  useEffect(() => {
    obterMensagemResposta();
  }, []);

  return (
    <ResultadoContainer>
      <CardScore>
        {score}/10 HITS
      </CardScore>
      <CardMessage className="resultado-joke">{titleMessage}</CardMessage>
      <p className="results-text">
        {subtituleMessage[0]}
        <br />
        <br />
        {subtituleMessage[1]}
        <br />
        <br />
        {subtituleMessage[2]}
      </p>
      <img className="video" src="/video-placeholder.png" alt="" />
    </ResultadoContainer>
  );
};

export default ResultadoQuiz;
