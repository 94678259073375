import React, {useEffect, useState} from "react";
import mensagens from "../../data/results.json";
import questionsData from "../../data/questions.json";
import ResultadoQuiz from "../../components/ResultadoQuiz";
import {
  Container,
  RoundedButton,
  CardHeaderImg,
  CardQuestion,
  CardOption,
} from "./style";

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isVisibleButton, setisVisibleButton] = useState(false);
  const [currentSelected, setCurrentSelected] = useState();
  const questions = questionsData.questions;
  const [activeVotation, setactiveVotation] = useState(true);

  const checkAnswerAndGoToNext = (e, selectedOptionIndex) => {
    setCurrentSelected(selectedOptionIndex);
    setactiveVotation(false);

    if (selectedOptionIndex === questions[currentQuestion].correctOptionIndex) {
      setScore(score + 1);
      setisVisibleButton(false);
      changeBtnColorsFromAnswer(selectedOptionIndex);

      setTimeout(() => {
        goToNextQuestion();
      }, 1500);
    } else {
      changeBtnColorsFromAnswer(selectedOptionIndex);
      setisVisibleButton(true);
      scrollToEndOfPage();
    }
  };

  const changeBtnColorsFromAnswer = (selectedOptionIndex) => {
    var wrongAnwerEl = document.querySelector(".opt-" + selectedOptionIndex);
    var rightAnswerEl = document.querySelector(
      ".opt-" + questions[currentQuestion].correctOptionIndex
    );
    rightAnswerEl.classList.add("right");
    wrongAnwerEl.classList.add("wrong");
  };

  const removeStyleFromOptions = () => {
    var allOptions = document.getElementsByClassName("option");
    Array.from(allOptions).forEach((opt) => {
      opt.classList.remove("right");
      opt.classList.remove("wrong");
    });
  };

  const goToNextQuestion = () => {
    setisVisibleButton(false);
    setactiveVotation(true);
    removeStyleFromOptions();
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setQuizCompleted(true);
    }
  };

  const scrollToEndOfPage = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };
  useEffect(() => {
    scrollToEndOfPage();
  });

  return (
    <Container>
      <CardHeaderImg
        src={
          quizCompleted
            ? "images/Results_1.png"
            : questions[currentQuestion].backgroundImage
        }
      />

      <div className="wrapper">
        {!quizCompleted ? (
          <div className="question-container">
            <CardQuestion>{questions[currentQuestion].question}</CardQuestion>

            <div className="options-container">
              {questions[currentQuestion].options.map((option, index) => (
                <CardOption
                  className={`option opt-${index}`}
                  key={index}
                  onClick={(e) => {
                    activeVotation && checkAnswerAndGoToNext(e, index);
                  }}
                >
                  {option}
                </CardOption>
              ))}
              {isVisibleButton && (
                <RoundedButton
                  className="continue-btn"
                  onClick={goToNextQuestion}
                >
                  CONTINUE &gt;
                </RoundedButton>
              )}
            </div>
          </div>
        ) : (
          <ResultadoQuiz
            score={score}
            mensagensResposta={mensagens.respostas}
          />
        )}
      </div>
    </Container>
  );
};

export default Quiz;
